export default {
  Dashboard: 'Dashboard',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'E-mail',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  Pages: 'Pages',
  Authentication: 'Authentification',
  Integrations: 'Integrations',
  General: 'General',
  Overview: 'Overview',
  Submit: 'Submit',
  Typography: 'Typographie',
  Icons: 'Icônes',
  Gamification: 'Gamification',
  Cards: 'Cartes',
  Basic: 'De base',
  Statistics: 'Statistiques',
  Advance: 'Avance',
  Actions: 'Actions',
  Chart: 'Graphique',
  Components: 'Composants',
  Alert: 'Alerte',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Bouton',
  Menu: 'Menu',
  Dialog: 'Dialogue',
  Pagination: 'Pagination',
  Tabs: 'Onglets',
  Tooltip: 'Info-bulle',
  Chip: 'Ébrécher',
  Snackbar: 'Snack-bar',
  Stepper: 'Pas à pas',
  Timeline: 'Chronologie',
  Treeview: 'Arborescence',
  Autocomplete: 'Saisie automatique',
  Checkbox: 'Case à cocher',
  Combobox: 'Boîte combo',
  Select: 'Sélectionner',
  Radio: 'Radio',
  Slider: 'Glissière',
  Switch: 'Changer',
  Textarea: 'Zone de texte',
  Textfield: 'Champ de texte',
  Rating: 'Notation',
  Miscellaneous: 'Divers',
  Error: 'Erreur',
  Pricing: 'Prix',
  FAQ: 'FAQ',
  Monday: 'Monday',
  image: 'image',
  Close: 'Close',
  color: 'color',
  Tuesday: 'Tuesday',
  Create: 'Create',
  Settings: 'Settings',
  Details: 'Details',
  Suspended: 'Suspended',
  Theming: 'Theming',
  Skin: 'Skin',
  Mode: 'Mode',
  Search: 'Search',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Weekdays: 'Weekdays',
  Tables: 'les tables',
  Charts: 'Graphiques',
  Datatable: 'Table de données',
  Apps: 'applications',
  Chartjs: 'Chartjs',
  Documentation: 'Documentation',
  Others: 'Autres',
  Filters: 'Filters',
  Filter: 'Filter ',
  Home: 'Home',
  Apply: 'Apply',
  Save: 'Save',
  Delete: 'Delete',
  Duplicate: 'Duplicate',
  Created: 'Created',
  Updated: 'Updated',
  Remove: 'Remove',
  Name: 'Name',
  Users: 'Users',
  Roles: 'Roles',
  Role: 'Role',
  Saved: 'Saved',
  global_standby_mode_timeframe: 'Timeframe',
  global_standby_mode_background_type: 'Background type',
  global_standby_mode_background_image: 'Background image',
  global_standby_mode_background_file: 'Background file',
  global_standby_mode: 'Global standby mode',
  Locations: 'Locations',
  Display: 'Display',
  Groups: 'Groups',
  Group: 'Group',
  Slug: 'Slug',
  Permissions: 'Permissions',
  Page: 'Page',
  Start: 'Start',
  End: 'End',
  Relative: 'Relative',
  Absolute: 'Absolute',
  Duration: 'Duration',
  From: 'From',
  Active: 'Active',
  Deactivated: 'Deactivated',
  Activate: 'Activate',
  Deactivate: 'Deactivate',
  Update: 'Update',
  Mirror: 'Mirror',
  Unmirror: 'Unmirror',
  Until: 'Until',
  Bordered: 'Bordered',
  Password: 'Password',
  Profile: 'Profile',
  Relativ: 'Relativ',
  Changelog: 'Changelog',
  Absolut: 'Absolut',
  configAbsolutDescription: 'Defines a fixed time frame in which the page should be visible (settings here override a relative time specification). Visibility can be defined in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.',
  configRelativDescription: 'Defines how long a page should be displayed until the next page appears. If no further page is available, this page remains visible.',
  Cancel: 'Cancel',
  Condition: 'Condition',
  Weekday: 'Week days',
  Connect: 'Connect',
  formwerk_api_key: 'API-Key',
  formwerk_api_url: 'API-Url',
  formwerk_token_secret_key: 'API-Token-Secret-Key',
  Disconnect: 'Disconnect',
  Security: 'Security',
  'FORMS & TABLES': 'FORMULAIRES & TABLEAUX',
  'Edit User Information': 'Edit User Information',
  'Page Not Found': 'Page Not Found',
  'we could not find the page you are looking for': 'we could not find the page you are looking for',
  'Back to home': 'Back to home',
  'Are you sure you want to delete this location and all sublocations?': 'Are you sure you want to delete this location and all sublocations?',
  'Are you sure you want to delete this page ?': 'Are you sure you want to delete this page ?',
  'Are you sure you want to delete this display ?': 'Are you sure you want to delete this display ?',
  'Forms Elements': 'Éléments de formulaires',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPLICATIONS ET PAGES',
  'Login V1': 'Connexion V1',
  'Login V2': 'Connexion V2',
  'Register V1': "S'inscrire V1",
  'Register V2': "S'inscrire V2",
  'Forgot Password?': 'Forgot Password?',
  'Remember Me': 'Remember Me',
  'Enter your email and we will send you instructions to reset your password': 'Enter your email and we will send you instructions to reset your password',
  'Send reset link': 'Send reset link',
  'Back to login': 'Back to login',
  'Add Timeframe': 'Add Timeframe',
  'Confirm password': 'Confirm password',
  'Reset Password V1': 'Réinitialiser le mot de passe V1',
  'Reset Password V2': 'Réinitialiser le mot de passe V2',
  'Under Maintenance': 'En maintenance',
  'Coming Soon': 'Bientôt disponible',
  'Not Authorized': 'Pas autorisé',
  'Expansion Panel': "Panneau d'extension",
  'Knowledge Base': 'Base de connaissances',
  'Account Settings': 'Paramètres du compte',
  'USER INTERFACE': 'INTERFACE UTILISATEUR',
  'File Input': 'Entrée de fichier',
  'Range Slider': 'Curseur de plage',
  'Simple Table': 'Tableau simple',
  'Date Picker': 'Sélecteur de date',
  'Month Picker': 'Sélecteur de mois',
  'Time Picker': 'Sélecteur de temps',
  'Form Layout': 'Disposition du formulaire',
  'Form validation': 'Validation du formulaire',
  'CHARTS AND MAPS': 'CARTES ET CARTES',
  'Apex Chart': 'Graphique Apex',
  'Leaflet Maps': 'Dépliant Cartes',
  'Menu Levels': 'Niveaux de menu',
  'Menu Level 2.1': 'Niveau de menu 2.1',
  'Menu Level 2.2': 'Niveau de menu 2.2',
  'Menu Level 3.1': 'Menu Niveau 3.1',
  'Menu Level 3.2': 'Niveau de menu 3.2',
  'Disabled Menu': 'Menu désactivé',
  'Raise Support': 'Augmenter le soutien',
  'Charts & Maps': 'Graphiques et cartes',
  'User Interface': 'Interface utilisateur',
  'Forms & Tables': 'Formulaires et tableaux',
  'Last edit': 'Last edit',
  'Configure Columns': 'Configure Columns',
  'Displayed records': 'Displayed records',
  'Oh snap! Change a few things up and try submitting again.': 'Oh snap!Change a few things up and try submitting again.',
  'All registered users': 'All registered users',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Create user': 'Create user',
  'Profile Information': 'Profile Information',
  'Ensure your account is using a long, random password to stay secure.': 'Ensure your account is using a long, random password to stay secure.',
  'A Role defines a set of tasks a user assigned the role is allowed to perform.': 'A Role defines a set of tasks a user assigned the role is allowed to perform.',
  'Update Password': 'Update password',
  'Current password': 'Current password',
  'New password': 'New password',
  'Confirm new password': 'Confirm new password',
  'Leave empty to keep current password': 'Leave empty to keep current password',
  'Specify which roles this account should belong to': 'Specify which roles this account should belong to',
  'Update your account\'s profile information and email address.': 'Update your account`s profile information and email address.',
  'My account': 'My account',
  'This is your password set at the moment.': 'This is your password set at the moment.',
  'A good password is at least 15 characters or at least 8 characters long, including a number and a lowercase letter.': 'A good password is at least 15 characters or at least 8 characters long, including a number and a lowercase letter.',
  'Specify which groups this account should belong to': 'Specify which groups this account should belong to',
  'A group combines users into an organizational unit.': 'A group combines users into an organizational unit.',
  'Are you sure?': 'Are you sure?',
  'Once the account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.': 'Once the account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.',
  'Access rights': 'Access rights',
  'Manage Roles': 'Manage Roles',
  'A role is a collection of privileges (of possibly different services like the Users service, Moderator, and so on) that grants users with that role the ability to perform certain tasks or operations.': 'A role is a collection of privileges (of possibly different services like the Users service, Moderator, and so on) that grants users with that role the ability to perform certain tasks or operations.',
  'Permission/Privilege': 'Permission/Privilege',
  'A privilege is necessary to perform certain tasks and operations in an area.': 'A privilege is necessary to perform certain tasks and operations in an area.',
  'Role display name': 'Role display name',
  'Role was saved': 'Role was saved',
  'Role can not be deleted as it has users attached': 'Role can not be deleted as it has users attached.',
  'The value field is required.': 'The value field is required.',
  'successfully saved': 'successfully saved',
  'successfully updated': ' successfully updated.',
  'The role.slug has already been taken.': 'The role.slug has already been taken.',
  'Defines a fixed time frame in which the standby mode should be active. Settings here override time specifications in page settings. Visibility can be set in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.': 'Defines a fixed time frame in which the standby mode should be active. Settings here override time specifications in page settings. Visibility can be set in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.',
  'Activate standby mode': 'Activate standby mode',
  'Standby mode': 'Standby mode',
  'Design during standby mode': 'Design during standby mode',
  'Title of the location': 'Title of the location',
  'Mirror display': 'Mirror display',
  'Select the display whose content should be mirrored to the current display.': 'Select the display whose content should be mirrored to the current display.',
  'This display mirrors contents of': 'This display mirrors contents of',
  'BuE appointments': 'BuE appointments',
  'Time relative visibility': 'Time relative visibility',
  'Set up automatic pagination': 'Set up automatic pagination',
  'Elements per page': 'Elements per page',
  'Page change after (Sec.)': 'Page change after (Sec.)',
  'Visibility duration': 'Visibility duration',
  'Defines how long a page should be displayed until the next page appears. If no further page is available, this page remains visible.': 'Defines how long a page should be displayed until the next page appears. If no further page is available, this page remains visible.',
  'Week days': 'Week days',
  'Defines a fixed time frame in which the page should be visible (settings here override a relative time specification). Visibility can be defined in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.': 'Defines a fixed time frame in which the page should be visible (settings here override a relative time specification). Visibility can be defined in pure time windows (e.g. 10:00 - 18:00), a date range (e.g. 01.01. - 15.01.) or by weekdays. Combinations of several entries further filter the visibility.',
  'Page active?': 'Page active?',
  'The password does not match the minimal requirements. Please choose a new one': 'The password does not match the minimal requirements. Please choose a new one',
  'Dark mode': 'Dark mode',
  'Default layout': 'Default layout',
  'Semi dark': 'Semi dark',
  '(The picture max not exceed 3 MB and needs to be in PNG or JPG format)': '(The picture max not exceed 3 MB and needs to be in PNG or JPG format)',
  'Add new user': 'Add new user',
  'Active displays': 'Active displays',
  'Active Locations': 'Active locations',
  'Displays in standby': 'Displays in standby',
  'Mirrored displays': 'Mirrored displays',
  'Add Location': 'Add Location',
  'Add Display': 'Add Display',
  'Settings saved!': 'Settings saved!',
  'Add New User': 'Add New User',
  'All Locations': 'All Locations',
  'Welcome to BuE DS!': 'BuE DS',
  'Help Desk': 'Help Desk',
  'Change log': 'Change log',
  'Add Page': 'Add Page',
  'more locations': 'more locations',
  'Settings-Saved': 'Settings Saved!',
  'Are you sure you want to delete this user ?': 'Are you sure you want to delete this user ?',
  'Connect display': 'Connect display',
  'Display successfully connected': 'Display successfully connected',
  'Display successfully disconnected': 'Display successfully disconnected',
  'Number of participants': 'Number of participants',
  'Edit Group': 'Edit Group',
  'Add Group': 'Add Group',
  'Are you sure you want to delete this group ?': 'Are you sure you want to delete this user ?',
  'Roles and Permissions': 'Roles and Permissions',
  'Are you sure you want to delete this role ?': 'Are you sure you want to delete this role ?',
  'Edit Role': 'Edit Role',
  'Add Role': 'Add Role',
  'This Role can not be deleted because it has assigned users': 'This Role can not be deleted because it has assigned users',
  'Standby mode background type': 'Standby mode background type',
  'Upload Backgroundimage': 'Upload Backgroundimage',
  'Current Backgroundimage': 'Current Backgroundimage',
  'Start Date': 'Start Date',
  'End Date': 'End Date',
  'Start Time': 'Start Time',
  'End Time': 'End Time',
  'Page active': 'Page active',
  'Settings saved': 'Settings saved',
  'This field is required': 'This field is required',
  'Title of the display': 'Title of the display',
  'Title of the page': 'Title of the page',
  'The email must be a valid email address.': 'The email must be a valid email address.',
  'Total Users': 'Total Users',
  'No time set': 'No time set',
  'The role was deleted successfully': 'The role was deleted successfully',
  'Select Display to Mirror': 'Select Display to Mirror',
  Disconnects: 'Disconnects',
}
